import { DetailViewGroup } from "@/interfaces/db"
import { Link } from "@/interfaces/property"

export * from "@/interfaces/api_key"
export * from "@/interfaces/audit"
export * from "@/interfaces/broker"
export * from "@/interfaces/client"
export * from "@/interfaces/connection"
export * from "@/interfaces/db"
export * from "@/interfaces/property"
export * from "@/interfaces/recipe"

export interface BaseCategory {
  id: number | string
  name: String
}

export interface NavigationTab {
  name: string
  url: string
  title: any
  badge?: Number
  disabled?: boolean
}

export interface DealBubble {
  name: string
  color: string
  chance: number
  count: number
}

export interface MediaDocument {
  id: number
  token: string
  title: string
  name: string
  url: string
  position: number
  broker_id: number
  is_private: boolean
  on_landing_page: boolean
  is_exposee: boolean
  second_document: null
  is_floorplan: boolean
  tags: string[]
  created_at: string
  updated_at: string
  client_id: null
  property_id: number
}

export interface Group extends DetailViewGroup {
  fields: Field[]
  action?: { label: string; fn: () => Promise<boolean> }
}
export interface Field {
  readonly?: boolean
  componentName: string
  fieldName: string
  hint?: string
  formField: FormField
  detailViewField?: any
  color?: string
  mandatory?: boolean
  containerPlaceholder?: string
  submitAttempt?: boolean
}
export interface FormField {
  label?: string
  group?: string
  filterable?: boolean | Function
  readonly?: boolean | Function
  filterName?: string
  type?: FormFieldType
  cantHaveRights?: boolean
  options?: FormFieldOption[] | ((options: any) => FormFieldOption[])
  dbOptions?: string
  prefix?: string
  unit?: string
  precision?: number
  scope?: FormFieldScope
  component?: string
  formula?: string
  snippets?: string
  limit?: number
  notForLp?: boolean
  async?: boolean
  step?: number
  max?: number
  refetchable?: boolean
  additionalParams?: (field: string, value: any) => any
}
export interface FormFieldOption {
  id: any
  name: string
  translate?: boolean
}
export enum FormFieldType {
  name = "name",
  emails = "emails",
  photo = "photo",
  address = "address",
  phone = "phone",
  link = "link",
  boolean = "boolean",
  date = "date",
  dropdown = "dropdown",
  slider = "slider",
  string = "string",
  text = "text",
  relationship = "relationship",
  children = "children",
  phones = "phones",
  multiselect = "multiselect",
  number = "number",
  richtext = "richtext",
  propertyAddress = "propertyaddress",
  range = "range",
  yesNo = "yesno",
}
export enum FormFieldScope {
  owner = "owner",
  partner = "partner",
  associate = "associate",
}

export interface Folder {
  id: number
  name: string
  folder: boolean
  sendable: boolean
  global?: boolean
  parentId?: number
  isPlaceholder?: boolean
  forUpload?: boolean
  description?: string
  maxFileSize?: number
  documentTags?: string[]
  allowedContentTypes?: string[]
  mandatory?: boolean
}

export interface DetailProject {
  id: number
  name: string
  brokerId: number
  warningNotice: string
  status: "ACQUISITION" | "PROGRESS" | "SALES" | "SOLD" | null
  createdAt: string
  updatedAt: string
  editableByCurrentBroker: boolean
  translatedLocales: string[]
  titleImageUrl: string
  propertiesCount: number
  links: Link[]
  archived: boolean
  landingPagePublished: boolean
  lpShareUrl: string
  slug: string
  oldCrmId: string
}

export interface FollowupActivityPayload {
  snippetId?: number | null
  brokerId?: number | null
  departmentId?: number | null
  dueDate?: number | null | string
  sendAt?: number | null | string
  noteTypeId?: number | null
  todoTypeId?: number | null
  eventTypeId?: number | null
  decisionTypeId?: number | null
  reviewerIds?: number[] | null
}

export interface FollowupActivity {
  id?: number
  activityType: "message" | "event" | "note" | "todo" | "letter" | "decision"
  actionType: String
  payload: FollowupActivityPayload
  _destroy?: Boolean
}

export interface ActivityCategory {
  body: string
  color: string
  taskPipelineId: number
  completedDealStageId: number
  completedFollowupActivities: FollowupActivity[]
  createdFollowupActivities: FollowupActivity[]
  hideInActivities: boolean
  hideInReports: boolean
  id: number
  internal: boolean
  name: string
  displayName: string
  nextDealStageId: number
  nextPropertyStatusId: number
  titleFormula: string
  publicTitleFormula?: string
  roomResourceEmails?: string[]
  todoActivities: any[]
  todos: Todo[]
  locked?: boolean
  reviewerIds?: number[]
  brokerId?: number
  departmentId?: number
  nextClientStatusId?: number
  createdDealBrokerId?: number
  completedDealBrokerId?: number
  senders?: Broker[]
  recipients?: Broker[]
  keywords?: string[]
  followupActivities?: FollowupActivity[]
  decisionOptions?: DecisionOption[]
}

export interface Todo {
  title: string
  brokerId: string | null
  dueDate: string | null
  departmentId: string | null
}

export interface DecisionOption {
  name: string
}

export interface ActivityCategoryWrapper {
  type: "message" | "event" | "note" | "todo" | "letter" | "decision"
  data: ActivityCategory
}

export interface TodoActivity {
  id?: Number
  activityType: "message" | "note" | "todo" | "cancelation" | "property"
  label?: String
  mandatory?: Boolean
  payload: TodoActivityPayload
  _destroy?: Boolean
}

export interface TodoActivityPayload {
  snippetId?: Number | null
  brokerId?: Number | null
  noteTypeId?: Number | null
  todoTypeId?: Number | null
  reservationReasonId?: Number | null
  propertyStatusId?: Number | null
  dueDate?: Number | null
  eventTypeId?: Number | null
  departmentId?: Number | null
  reviewerIds?: number[] | null
}

export interface BrokerInbox {
  email: string
  id: number
  internal_name: string
  name: string
  signature?: string
}

export interface Broker {
  admin: boolean
  archived: boolean
  available_inboxes: BrokerInbox[]
  all_available_mailboxes: BrokerInbox[]
  avatar?: string
  can_delete_clients: boolean
  can_delete_properties: boolean
  can_edit_units: boolean
  can_edit_shared_units: boolean
  can_export: boolean
  can_export_to_portals: boolean
  can_export_clients: boolean
  can_see_all_clients: boolean
  can_find_all_clients: boolean
  can_see_todos: boolean
  can_send_emails: boolean
  can_sync_contacts: boolean
  can_import: boolean
  can_bulk_edit: boolean
  cell: string
  clients_open_in_details: boolean
  color: string
  connected: boolean
  department_ids: number[]
  dob: string
  email: string
  hideInDropdowns: boolean
  id: number
  initials: string
  name: string
  phone: string
  position?: string
  primary_sender_broker_id?: number
  sender_email: string
  team_id?: number
  team?: string
  getReminderEmails?: boolean
  mfaEnabled?: boolean
  allowedIpAddresses?: string[]
  role?: string
  notificationEnabled?: boolean
}

export interface Column {
  name?: string
  title?: string
  key: string
  width?: number
  sort?: boolean | string | Function
  main?: boolean
}
