<template>
  <dv-row>
    <template v-slot:label>
      <span v-html="field.formField.label"></span>
      <span v-if="field.mandatory">*</span>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'flex w-100',
      ]"
    >
      <nice-input
        type="number"
        :model-value="value"
        readonly
        :style="!value && `background-color: ${field.color}ff`"
        :data-value-missing="!value"
        class="rounded"
        :placeholder="field.containerPlaceholder"
      />
    </div>
  </dv-row>
</template>

<script>
import { age } from "@/config/date-filters"

export default {
  props: ["record", "field"],
  computed: {
    value() {
      return age(this.record.dob)
    },
  },
}
</script>
<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
